.table-container {
    width: 100%;
    height: 100%;
    background-color: #131316;
    padding-left: 24px;
    padding-right: 100px;
    margin-top: 40px;
    margin-bottom: 45px;
}

.table-row {
    background-color: #131316;
}

.no-action-div {
    margin-top: 30px;
}

.ddt-calendar:global(.MuiPickersPopper-paper) {
    border-radius: 16px;
    background-color: #2A2A2D;
}

.desktop-paper:global(.MuiPickersPopper-paper) {
    border-radius: 16px;
    overflow: hidden;
    font-size: 16px;
    width: 320px;
}

.day-paper:global(.MuiPickersDay-root) {
    font-size: 16px;
}