.error-card-content {
    width: 60%;
    height: 249px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-self: center;
}

.error-row {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.error-button {
    width: 144px;
    gap: 8px;
    text-align: center;
}

.error-icon {
    color: #FFB4AC;
    padding: 4px;
}

.text-with-icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.transparent-dialog:global(.MuiPaper-root) {
    background-color: transparent;
    box-shadow: none;
    background-image: none;
    border-radius: 0;
}