.detail-dossier-background {
    background-color: #131316;
    overflow: hidden;
}

.detail-dossier-container {
    margin-top: 80px;
    overflow-y: hidden;
    margin-bottom: 88px;
}

.detail-dossier-bottom-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 24px 64px 24px 64px;
    gap: 8px;
}

.detail-dossier-bottom-bar-stepRecap {
    gap: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;

    padding-left: 64px;

}

.detail-dossier-footer {
    position: absolute;
    height: 88px;
    width: 100%;
    bottom: 0;
    background-color: #131316;
    z-index: 10;
}

.detail-dossier-stepper-container {
    position: absolute;
    width: 100%;
    height: 80px;
    background-color: #131316;
    overflow: hidden;
}

.bottom-button:global(.MuiButton-root) {
    font-weight: bold;
}

.button-cancel:global(.MuiButton-root) {
    width: 144px
}

.button-confirm-create:global(.MuiButton-root) {
    width: 174px;
    gap: 6px
}

.left-icon {
    margin-right: 5px;
}

.right-icon {
    margin-left: 5px;
}