.paper-container {
    width: auto;
    overflow: hidden;
    height: fit-content;
    margin-top: 30px;
}

.table-container {
    width: 100%;
    height: 100%;
    background-color: #131316;
}

.table-header-cell:global(.MuiTableCell-root) {
    background-color: #131316;
    color: #C2C5DD;

}

.cursor-pointer {
    cursor: pointer;
}

.flex-cell {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.menu-container {
    width: 304px;
    border-radius: 4px;
}

.item {
    height: 48px;
}