.paperwork-background {
    background-color: #131316;
    margin-bottom: 50px;
    overflow-y: hidden;
}

.paperwork-list-pagination-div {
    position: fixed;
    bottom: 0;
    width: calc(100% - 80px);
}

.paperwork-list-pagination {
    background-color: #1B1B1F;

}

.paper-container {
    width: auto;
    margin-right: 24px;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    background-color: #131316;


}

.table-container {
    width: 100%;
    height: 100vh;
    background-color: #131316;
    padding-left: 24px;
    padding-right: 100px;
}

.table-content {
    padding-bottom: 256px;
}

.table-no-content {
    padding-bottom: 40px;
}

.filter-list-container {
    margin-top: 8px;
    height: 88px;
}

.filter-container {
    display: flex;
    flex-direction: row;
    gap: 24px;
    padding-left: 24px;
    padding-right: 24px;
    width: 80%;
}

.table-header-cell:global(.MuiTableCell-root) {
    background-color: #131316;
    color: #C2C5DD;
}

.cursor-pointer {
    cursor: pointer;
}

.icon-table-sort:global(.MuiSvgIcon-root) {
    opacity: 1;
    height: 18px;
    width: 18px;
    margin-left: 2px
}

.fixed-tab-filters {

    background-color: #131316;
    width: calc(100% - 80px);
    z-index: 10;
    height: 80px;

}

.custom-alert-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 300px;
}